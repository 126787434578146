<template>
  <div>
    <PageDynamicBreadcrumb />

    <PageHeader title="Keyword Modifier Creator" />

    <q-form @submit="createKeywordModifier">
      <q-card flat bordered>
        <q-card-section>
          <h5>Modifier Details</h5>
        </q-card-section>
        <q-card-section>
          <div class="q-gutter-y-md">
            <q-select
              filled
              v-model="businessAccountId"
              :options="businessAccountOptions"
              label="Business Account"
              emit-value
              map-options
              disable="disable"
              hint="Currently restricted to Media.net Ads only."
            ></q-select>
            <q-select
              filled
              v-model="brandId"
              :options="brandOptions"
              label="Brand"
              emit-value
              map-options
              :rules="[val => (val && val.length > 0) || 'Select a Brand.']"
            ></q-select>
            <q-input
              v-model="keywordModifier"
              filled
              type="input"
              label="Keyword Modifier"
              hint="Add Base Keyword to modifier by inserting {{kw}}. (ex. Top {{kw}} Deals = Top Honda Civic Deals)"
              :rules="[
                val =>
                  (val &&
                    val.length > 0 &&
                    val.indexOf('{{kw}}') != -1 &&
                    val != '{{kw}}') ||
                  'Invalid Keyword Modifier value. Keyword Modifier must be a string and contain {{kw}}.'
              ]"
            />
          </div>
        </q-card-section>
      </q-card>
      <FormActionsSection>
        <template v-slot:primary-actions>
          <q-btn
            label="Create Modifier"
            type="submit"
            color="primary"
            :disable="submitting"
            :loading="submitting"
          />
          <q-btn
            flat
            label="Cancel"
            to="/manage/keywords/modifiers"
            color="subtle"
            :disable="submitting"
          />
        </template>
      </FormActionsSection>
    </q-form>
  </div>
</template>

<script>
import axios from "axios";
import PageHeader from "@/components/UI/PageHeader";
import PageDynamicBreadcrumb from "@/components/UI/PageDynamicBreadcrumb";
import FormActionsSection from "@/components/UI/FormActionsSection";

export default {
  name: "KeywordModifierCreator",
  components: {
    PageDynamicBreadcrumb,
    PageHeader,
    FormActionsSection
  },
  data() {
    return {
      businessAccountId: "",
      businessAccountOptions: [],
      brandOptions: [],
      keywordModifier: "",
      brandId: [],
      submitting: false
    };
  },
  mounted() {
    this.$store.dispatch("brands/POPULATE_LIST").finally(() => {
      this.brandOptions = this.generateSelectOptionsArray(
        this.$store.state.brands.list,
        "name",
        "id"
      );
    });
    this.$store.dispatch("businesses/POPULATE_ACCOUNTS_LIST").finally(() => {
      this.businessAccountOptions = this.generateSelectOptionsArray(
        this.$store.state.businesses.accounts.list,
        "name",
        "id"
      );
      this.businessAccountId = "1009";
    });
  },
  methods: {
    createKeywordModifier() {
      this.submitting = true;
      axios
        .post(
          "https://api-v2.jumpfeed.com/internal/keywords/modifiers/create",
          {
            brand_id: this.brandId,
            keyword_modifier: this.keywordModifier,
            business_account_id: this.businessAccountId
          }
        )
        .then(() => {
          this.triggerActionOutcomeAlert("create", true);
          this.$router.push("/manage/keywords/modifiers");
        })
        .catch(err => {
          console.log(err);
          this.triggerActionOutcomeAlert("create", false);
        })
        .finally(() => {
          this.submitting = false;
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>
